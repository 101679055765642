@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Open+Sans:wght@300;600&family=Pacifico&family=Poppins:wght@600&family=Roboto:wght@400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}

section {
  background-color: rgb(245, 245, 245);
  transition: all 300ms ease;
}

input,
textarea {
  width: 100%;
  background-color: #242424;
  color: white;
  outline: none;
  border: 2px solid #c0c4cc;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 40px;
  transition: all ease 300ms;
}

textarea {
  resize: vertical;
  height: 100px;
  margin-top: 8px;
}

input:hover,
textarea:hover {
  border-color: white;
}

input:focus,
textarea:focus {
  border-color: rgb(75, 0, 130);
}

label {
  font-size: 14px;
  font-weight: bold;
}

.purple {
  color: rgb(75, 0, 130) !important;
}

.bold {
  font-weight: 700;
}

.lilita {
  font-family: "Lilita One", cursive;
}

.pacifico {
  font-family: "Pacifico", cursive;
  position: relative;
  top: 4px;
  font-size: 26px;
  letter-spacing: 2px;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.open-sans {
  font-family: "Open Sans", sans-serif;
}

.ml4 {
  margin-left: 4px;
}

.click {
  transition: all 300ms ease;
}

.click:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.click:active {
  transform: scale(0.8);
}

.link__hover-effect {
  position: relative;
}

.link__hover-effect::after {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 0;
  height: 3px;
  transition: all 300ms ease;
  width: 0;
}

.link__hover-effect--white::after {
  background-color: white;
}

.link__hover-effect--black::after {
  background-color: #242424;
}

.link__hover-effect:hover::after {
  left: 0;
  width: 100%;
}

nav,
.scroll,
.header,
.mail__btn--wrapper {
  opacity: 1;
  visibility: visible;
  transition: visible 600ms 800ms, opacity 600ms 800ms;
}

.modal--open nav,
.modal--open .scroll,
.modal--open .header,
.modal--open .mail__btn--wrapper {
  opacity: 0;
  visibility: hidden;
  transition: visible 400ms, opactiy 400ms;
}

.dark-theme .title,
.dark-theme .section__title,
.dark-theme .section__sub-title,
.dark-theme .nav__link--anchor,
.dark-theme .dark-mode--btn,
.dark-theme .header__para,
.dark-theme .logo,
.dark-theme .experience__list--triangle {
  color: white;
}

.dark-theme .experience {
  background-color: rgb(12, 12, 12);
  border-color: white;
  color: white;
  -webkit-box-shadow: 0px 0px 5px 2px rgb(75, 0, 130);
  -moz-box-shadow: 0px 0px 5px 2px rgb(75, 0, 130);
  box-shadow: 0px 0px 5px 2px rgb(75, 0, 130);
}

.dark-theme .experience__list::before,
.dark-theme .experience__wrapper:nth-child(n):after {
  background-color: white;
}

.dark-theme .experience__wrapper:nth-child(n):before {
  background-color: #242424;
  border-color: white;
  -webkit-box-shadow: 0px 0px 4px 3px rgb(75, 0, 130);
  -moz-box-shadow: 0px 0px 4px 3px rgb(75, 0, 130);
  box-shadow: 0px 0px 4px 3px rgb(75, 0, 130);
}

.dark-theme .scroll__icon {
  border-color: white;
}

.dark-theme .scroll__icon::after {
  background-color: white;
}

.dark-theme .mail__btn {
  color: #242424;
  background-color: white;
}

.dark-theme .link__hover-effect--black::after {
  background-color: white;
}

.dark-theme section {
  background-color: #242424;
}

/* Landing page */

#landing-page {
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

.header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1000px;
  padding: 0 50px;
  z-index: 2;
}

.title {
  font-size: 80px;
  margin-bottom: 12px;
  text-align: left;
  line-height: 1;
}

.header__para {
  font-size: 20px;
  line-height: 2;
  max-width: 450px;
}

.about-me__links {
  margin-top: 16px;
  display: flex;
}

.about-me__link {
  color: white;
  border-radius: 9999px;
  background-color: rgb(75, 0, 130);
  padding: 6px;
  width: 32px;
  height: 32px;
  font-size: 18px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.mail__btn--wrapper {
  position: fixed;
  bottom: 32px;
  right: 40px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mail__btn {
  background-color: #242424;
  box-shadow: 0 12px 30px 0 rgb(0, 0, 0, 0.9);
  background-color: #242424;
  font-size: 32px;
  border: none;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 9999px;
}

.scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid #242424;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll__icon::after {
  content: "";
  width: 4px;
  height: 6px;
  border-radius: 4px;
  background-color: #242424;
  animation: scroll 1000ms infinite alternate-reverse;
}

@keyframes scroll {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}

/* navigation */

nav {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.logo {
  font-family: "Alatsi", sans-serif;
  font-size: 32px;
}

#personal-logo {
  width: 50px;
  height: 50px;
}

.nav__link--list {
  display: flex;
}

li {
  list-style: none;
}

.nav__link {
  margin: 0 12px;
}

.nav__link--anchor {
  text-decoration: none;
  font-size: 16px;
  color: #242424;
  font-weight: 700;
}

.fa-circle-half-stroke {
  font-size: 20px;
}

/* Modal */

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1100px;
  height: 700px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  transition: visibility 1s, z-index 1s, box-shadow 1s ease;
  z-index: -1;
}

.modal--open .modal {
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.55);
  visibility: visible;
  z-index: 2;
}

.modal__half {
  width: 50%;
  padding: 40px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 300ms ease-in;
}

.modal--open .modal__about {
  transform: translateX(0%);
}

.modal__about {
  background-color: rgb(245, 245, 245);
  transform: translateX(-110%);
}

.modal__title {
  font-size: 26px;
}

.modal__sub-title {
  margin: 12px 0 24px 0;
  font-size: 17px;
}

.modal__para {
  margin-bottom: 12px;
  line-height: 1.5;
}

.modal__para .purple {
  font-weight: 700;
}

.modal__languages {
  display: flex;
  flex-wrap: wrap;
}

.modal__language {
  width: 25%;
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease;
  margin-bottom: 4px;
}

.modal__language:hover {
  filter: brightness(80%);
  transform: scale(0.9);
}

.modal__language:hover .language__name {
  transform: scale(1);
}

.language__name {
  position: absolute;
  bottom: -15px;
  transform: scale(0);
  transition: all 300ms ease;
}

.modal__language img {
  width: 100%;
}

.modal--open .modal__contact {
  transform: translateX(0%);
}

.modal__contact {
  background-color: #242424;
  color: white;
  transform: translateX(110%);
}

.form__item {
  margin-bottom: 20px;
}

.form__submit {
  background-color: rgb(75, 0, 130);
  border: 2px solid rgb(75, 0, 130);
  color: white;
  width: 100%;
  max-width: 200px;
  padding: 12px 24px;
  font-size: 20px;
  font-weight: bold;
  transition: all ease 400ms;
}

.form__submit:hover {
  border-color: white;
  background-color: transparent;
}

.form__submit:active {
  border-color: rgb(75, 0, 130);
  color: rgb(75, 0, 130);
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
  display: none;
}

.modal__overlay--loading {
  background-color: #242424;
  font-size: 80px;
}

.modal__overlay--success {
  background-color: #4bb543;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
}

.modal__overlay--visible {
  z-index: 1;
  display: flex;
}

.fa-spinner {
  animation: spinner 1000ms infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal__exit {
  position: absolute;
  top: 30px;
  right: 40px;
  color: white;
  font-size: 36px;
  z-index: 5;
}

/* Section Classes */

.container {
  padding: 50px 0;
  width: 100%;
  position: relative;
  z-index: 2;
}

.row {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 80px;
}

.section__title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
}

/* Experience */

.experience__list {
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.experience__list::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
  background-color: black;
  width: 2px;
  height: 100%;
  z-index: 5;
}

.experience__list--triangle {
  position: absolute;
  top: -16px;
  left: calc(100% + 1px);
  transform: scale(var(--ggs, 1)) translateX(-50%);
  width: 22px;
  height: 17px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid;
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
}

.experience__list--triangle::before {
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 3px solid;
  border-top: 3px solid;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg) skew(10deg, 10deg);
  left: -2px;
  bottom: -13px;
}

.experience__wrapper {
  width: 50%;
  display: flex;
  position: relative;
}

.experience {
  background-color: white;
  border: 2px solid black;
  border-radius: 24px;
  padding: 8px 16px;
  width: 80%;
  max-width: 350px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px 1px rgb(75, 0, 130);
  -moz-box-shadow: 0px 0px 5px 1px rgb(75, 0, 130);
  box-shadow: 0px 0px 5px 1px rgb(75, 0, 130);
  z-index: 20;
}

.experience__wrapper:nth-child(odd) {
  align-self: start;
  justify-content: start;
}

.experience__wrapper:nth-child(odd)::after {
  content: "";
  z-index: 5;
  position: absolute;
  width: 50%;
  height: 2px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
}

.experience__wrapper:nth-child(odd)::before {
  content: "";
  z-index: 6;
  position: absolute;
  width: 6px;
  height: 6px;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 2px solid black;
  border-radius: 9999px;
  -webkit-box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
  -moz-box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
  box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
}

.experience__wrapper:nth-child(even) {
  align-self: end;
  justify-content: end;
}

.experience__wrapper:nth-child(even)::after {
  content: "";
  z-index: 5;
  position: absolute;
  width: 50%;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
}

.experience__wrapper:nth-child(even)::before {
  content: "";
  z-index: 6;
  position: absolute;
  width: 6px;
  height: 6px;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 2px solid black;
  border-radius: 9999px;
  -webkit-box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
  -moz-box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
  box-shadow: 0px 0px 3px 1px rgb(75, 0, 130);
}

.experience__company {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

#frontendSimplified {
  margin-right: 8px;
}

#uakron {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#uakron__logo {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

.experience__company div {
  display: flex;
  align-items: center;
}

.experience__company p {
  font-size: 18px;
}

.prcSaltillo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777777;
}

.prcSaltillo__logo {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

#codehouseSolutions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

#codehouse__logo {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.experience__title {
  font-size: 18px;
  margin-bottom: 8px;
}

.experience__technologies {
  max-width: 80%;
  margin: 0 auto 20px;
}

.experience__technologies--title {
  font-weight: bold;
  display: block;
  margin-bottom: 1px;
}

.experience__date {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: rgb(75, 0, 130);
}

/* Move Timeline */

@media (max-width: 928px) {
  .experience__list::before {
    left: 90%;
  }

  .experience__wrapper:nth-child(even) {
    align-self: start;
    justify-content: start;
  }

  .experience__wrapper:nth-child(odd)::after {
    content: "";
    z-index: 5;
    position: absolute;
    width: 50%;
    height: 2px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: black;
  }

  .experience__wrapper:nth-child(even)::after {
    content: "";
    z-index: 5;
    position: absolute;
    width: 50%;
    height: 2px;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: black;
  }

  .experience__wrapper:nth-child(even)::before {
    left: auto;
    right: -5px;
  }

  .experience__wrapper {
    width: 90%;
    margin-top: 60px;
  }

  .experience__wrapper:first-child {
    margin-top: 0;
  }

  .experience {
    max-width: 400px;
  }
}

/* Projects */

.project {
  margin-bottom: 80px;
}

.project:first-child {
  margin-top: 40px;
}

.project__mockup {
  width: 100%;
  transition: all 450ms ease;
}

.project__wrapper {
  position: relative;
  border-radius: 20px;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
}

.project__wrapper--twitter,
.project__wrapper--space-model {
  background-color: black;
}

.project__wrapper:hover .project__description {
  opacity: 1;
  transform: translateY(-50%);
}

.project__wrapper:hover .project__mockup {
  transform: scale(1.07);
  filter: blur(5px);
}

.project__wrapper:hover .project__wrapper--bg {
  opacity: 0.7;
}

.project__description {
  position: absolute;
  top: 50%;
  transform: translateY(100%);
  left: 90px;
  color: white;
  max-width: 550px;
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
}

.project__wrapper--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
}

.project__description--title {
  font-size: 40px;
  margin-bottom: 6px;
}

.project__description--para {
  margin: 16px 0;
  width: 80%;
  line-height: 1.5;
}

.project__description--link {
  color: white;
  font-size: 20px;
  margin-right: 16px;
  text-decoration: none;
}

/* footer */

footer {
  background-color: #242424;
  display: flex;
  padding: 6% 0;
  position: relative;
  z-index: 2;
}

.footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__logo,
.footer__social--link,
.footer__copyright {
  color: white;
}

.footer__logo {
  font-size: 40px;
}

.footer__social--list {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  margin: 24px 0;
}

.footer__social--link {
  text-decoration: none;
}

/* Shapes */

.modal--open .shape {
  opacity: 0;
}

.shapes__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shape {
  position: fixed;
  z-index: 1;
  opacity: 1;
}

.shape--0 {
  top: 15vh;
  left: 10vw;
}
.shape--1 {
  top: 15vh;
  left: 50vw;
}
.shape--2 {
  top: 15vh;
  left: 85vw;
}
.shape--3 {
  top: 50vh;
  left: 10vw;
}
.shape--4 {
  top: 50vh;
  left: 50vw;
}
.shape--5 {
  top: 50vh;
  left: 85vw;
}
.shape--6 {
  top: 80vh;
  left: 10vw;
}
.shape--7 {
  top: 80vh;
  left: 50vw;
}
.shape--8 {
  top: 80vh;
  left: 85vw;
}

/* small laptop, tablet, large smart phone */

@media (max-width: 768px) {
  .title {
    font-size: 75px;
  }

  .header__para {
    font-size: 20px;
  }

  .modal {
    top: 0;
    left: 0;
    transform: none;
    height: auto;
    width: 100%;
    flex-direction: column-reverse;
    border-radius: 0;
  }

  .modal__language img {
    width: 70%;
  }

  .modal__half {
    width: 100%;
  }

  .project__description--para {
    display: none;
  }

  .project__description {
    left: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .project__description--sub-title {
    margin-bottom: 8px;
  }

  .experience__company {
    font-size: 20px;
  }

  .pacifico {
    top: 3px;
    font-size: 22px;
  }

  .prcSaltillo__logo {
    width: 20px;
    height: 20px;
  }

  #codehouseSolutions {
    font-size: 18px;
  }

  #codehouse__logo {
    width: 20px;
    height: 20px;
  }

  .experience__title {
    font-size: 17px;
  }

  .experience__technologies {
    font-size: 15px;
  }

  .experience__date {
    font-size: 15px;
  }
}

/* small phones */

@media (max-width: 500px) {
  .title {
    font-size: 52px;
  }

  .header__para {
    font-size: 18px;
  }

  .nav__link:nth-child(2) {
    display: none;
  }

  .modal__half {
    padding: 40px;
  }

  .experience__wrapper {
    width: 100%;
  }

  .experience__list::before {
    left: 100%;
  }

  .experience__list--triangle {
    left: 100%;
  }

  .row {
    padding: 0 40px;
  }

  .experience__company {
    font-size: 18px;
  }

  .pacifico {
    font-size: 19px;
  }

  .prcSaltillo__logo {
    width: 18px;
    height: 18px;
  }

  #codehouseSolutions {
    font-size: 17px;
  }

  #codehouse__logo {
    width: 18px;
    height: 18px;
  }

  .experience__title {
    font-size: 16px;
  }

  .experience__technologies {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .experience__date {
    text-align: center;
  }
}

@media screen and (max-height: 1000px) and (max-width: 768px) {
  #landing-page {
    min-height: 110vh;
  }
}

@media screen and (max-height: 900px) and (max-width: 768px) {
  #landing-page {
    min-height: 115vh;
  }
}

@media screen and (max-height: 850px) and (max-width: 768px) {
  #landing-page {
    min-height: 120vh;
  }
}

@media screen and (max-height: 810px) and (max-width: 768px) {
  #landing-page {
    min-height: 125vh;
  }
}

@media screen and (max-height: 1000px) and (max-width: 768px) {
  #landing-page {
    min-height: 110vh;
  }
}

@media screen and (max-height: 900px) and (max-width: 768px) {
  #landing-page {
    min-height: 115vh;
  }
}

@media screen and (max-height: 850px) and (max-width: 768px) {
  #landing-page {
    min-height: 120vh;
  }
}

@media screen and (max-height: 810px) and (max-width: 768px) {
  #landing-page {
    min-height: 125vh;
  }
}
